import "selectedIcons";
import "components/top-menu";
import "components/header";
import "components/footer";
import "bootstrap/js/dist/alert";
import $ from "jquery";
import "@progress/kendo-ui/js/kendo.autocomplete";
import "@progress/kendo-ui/js/kendo.grid";
import "@progress/kendo-ui/js/kendo.aspnetmvc";

$(document).ready(() => {
    $("#searchForm").keydown(event => {
        const key = event.which;
        if (key === 13) {
            //const accountNo = event.item.text().split("|")[0].trim();
            //window.location.href = `/Details/${accountNo}`;

            $("#searchForm").submit();
        }
    });
});